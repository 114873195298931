body a:hover {
  color: rgb(0, 96, 131);
}

.nav-style-two ul.prime-nav > li > a:hover {
  color: rgb(0, 96, 131);
}

ul.prime-nav li > ul.bluebox-dropdown li a:hover {
  color: rgb(0, 96, 131);
}

ul.prime-nav li > ul.bluebox-dropdown li.current-menu-item > a {
  color: rgb(0, 96, 131);
}

.dark-dropdown.bluebox-header ul.prime-nav li > ul.bluebox-dropdown li a:hover {
  color: rgb(0, 96, 131);
}

.dark-dropdown.bluebox-header ul.prime-nav li > ul.bluebox-dropdown li.current-menu-item > a {
  color: rgb(0, 96, 131);
}

.roki-rev-slide-vl-opt2-opt1 {
  color: rgb(0, 96, 131);
  background-color: rgb(255, 255, 255);
}

.roki-rev-slide-vl-opt4-opt1 {
  color: rgb(0, 96, 131);
  background-color: rgba(0, 0, 0, 0.74);
}

.roki-rev-slide-vl-no-opt1 {
  color: rgb(0, 96, 131);
}

.roki-rev-slide-l-opt2-opt1 {
  color: rgb(0, 96, 131);
  background-color: rgb(255, 255, 255);
}

.roki-rev-slide-l-opt4-opt1 {
  color: rgb(0, 96, 131);
  background-color: rgba(0, 0, 0, 0.74);
}

.roki-rev-slide-l-no-opt1 {
  color: rgb(0, 96, 131);
}

.roki-rev-slide-n-opt2-opt1 {
  color: rgb(0, 96, 131);
  background-color: rgb(255, 255, 255);
}

.roki-rev-slide-n-opt4-opt1 {
  color: rgb(0, 96, 131);
  background-color: rgba(0, 0, 0, 0.74);
}

.roki-rev-slide-n-no-opt1 {
  color: rgb(0, 96, 131);
}

.bluebox-accordion .accordion-heading a.accordion-button:hover {
  color: rgb(0, 96, 131);
}

.bluebox-accordion .accordion-heading:hover {
  color: rgb(0, 96, 131);
}

.nav-tabs > .active > a {
  color: rgb(0, 96, 131);
}

.nav-tabs > .active > a:hover {
  color: rgb(0, 96, 131);
}

.nav-tabs > .active > a > span {
  color: rgb(0, 96, 131);
}

.nav-tabs > .active > a:hover > span {
  color: rgb(0, 96, 131);
}

.nav-tabs > li > a:hover > span {
  color: rgb(0, 96, 131);
}

ol.comment-list li.comment div.comment-author cite a:hover {
  color: rgb(0, 96, 131);
}

ol.comment-list li.comment div.comment-meta a:hover {
  color: rgb(0, 96, 131);
}

.hover-light:hover {
  color: rgb(0, 96, 131) !important;
  background-color: rgb(255, 255, 255) !important;
}

.hover-light:hover span {
  color: rgb(0, 96, 131) !important;
}

.bluebox-new-tabs-element > .nav-left ul li.active > a {
  color: rgb(0, 96, 131) !important;
}

.widget-wrap ul.bluebox-widget-menu a:hover span {
  color: rgb(0, 96, 131);
}

.bluebox-footer-content .widget-wrap ul.bluebox-widget-menu a:hover span {
  color: rgb(0, 96, 131);
}

.bluebox-custom-social-link {
  color: rgb(255, 255, 255);
}

.bluebox-custom-social-link:hover {
  color: rgb(0, 96, 131);
}

.bluebox-events-list:hover .event-details .page-heading-wrap ul.event-rate-list li {
  color: rgb(0, 96, 131);
}

.bluebox-new-tabs-element > .nav-left ul li > a:hover {
  color: rgb(0, 96, 131);
}

.bluebox-new-tabs-element > .nav-left ul .sub-menu li a:hover {
  color: rgb(0, 96, 131);
}

::selection {
  background: rgb(0, 96, 131);
}

.bluebox-button {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(0, 96, 131);
}

.roki-button-link {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(0, 96, 131);
}

.bluebox-button:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.roki-button-link:hover {
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.74) !important;
}

.bluebox-info-bar {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.bluebox-prime-nav #prime-nav-searchform {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.nova-slider-bottom-nav .bluebox-slider-content ul li.current a {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.nova-slider-bottom-nav .bluebox-slider-content ul li.current a:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li.current a {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li.current a:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.roki-rev-slide-vl-opt1-opt2 {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.roki-rev-slide-vl-opt1-opt4 {
  color: rgb(0, 0, 0);
  background-color: rgb(0, 96, 131);
}

.roki-rev-slide-l-opt1-opt2 {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.roki-rev-slide-l-opt1-opt4 {
  color: rgb(0, 0, 0);
  background-color: rgb(0, 96, 131);
}

.roki-rev-slide-n-opt1-opt2 {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.roki-rev-slide-n-opt1-opt4 {
  color: rgb(0, 0, 0);
  background-color: rgb(0, 96, 131);
}

.roki-rev-slide-extra-text-bullets::after {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.bluebox-heading-wrap .prev-next-heading-links a:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

ul.bluebox-pagination li.current a {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.tagline a:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.load-more-link:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.tagline a.current {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.tagline li.current a {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.bluebox-accordion .ui-state-active.accordion-heading {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.progress .bar {
  background-color: rgb(0, 96, 131);
}

.bluebox-fancy-list li span {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.bluebox-partners ul.partners-slide li a:hover::before {
  background-color: rgb(0, 96, 131);
}

.bluebox-contact input[type='submit'] {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.bluebox-staff .content-details::before {
  background-color: rgb(0, 96, 131);
}

.bluebox-icon-container:hover {
  background-color: rgb(0, 96, 131);
}

.text-box-style-two .head-link:hover span {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.fancy-colorful.bluebox-fancy-text {
  background-color: rgb(0, 96, 131);
}

.fancy-colorful.full-width.bluebox-fancy-text .element-background {
  background-color: rgb(0, 96, 131);
}

.quote-colorful.bluebox-quotes {
  background-color: rgb(0, 96, 131);
}

.full-width.quote-colorful.bluebox-quotes {
  background-color: rgb(0, 96, 131);
}

.span12 .quote-colorful.full-width.bluebox-quotes .element-background {
  background-color: rgb(0, 96, 131);
}

.quote-colorful.quote-fancy.bluebox-quotes {
  background-color: rgb(0, 96, 131);
}

.blog-loop-standard .line-details .line-date {
  background-color: rgb(0, 96, 131);
}

.tagcloud a:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.bluebox-footer-content .tagcloud a:hover {
  background-color: rgb(0, 96, 131);
}

.bluebox-footer-content a.social-link:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.bluebox-authors-wrap .bluebox-authors-content a.back-to-top:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.prime-nav-searchform-button-active {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.bluebox-single-staff-wrap .top-staff-wrap .single-staff-left-wrap::before {
  background-color: rgb(0, 96, 131);
}

.bluebox-single-staff-wrap .bottom-staff-wrap .single-staff-left-wrap .single-social-link:hover span {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.new-builder-element.bluebox-single-staff-wrap .single-staff-right-wrap .page-heading-wrap .staff-more-link:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.cal-page-month-wrap a.nav-arrow:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.bluebox-events-list:hover .event-date-line {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.bluebox-events-list:hover .event-date-line::before {
  background-color: rgb(0, 96, 131);
}

.bluebox-events-list .event-details .event-inner-content-wrap .event-more-link:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.bluebox-new-tabs-element > .nav-left ul li.active > a > span {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.bluebox-new-tabs-element > .nav-left ul li.active > a::before {
  background-color: rgb(0, 96, 131);
}

.large.bluebox-new-tabs-element > .nav-left ul li.active > a > span {
  background-color: rgb(0, 96, 131);
}

div.note-design-wrap td.event a:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

div.note-design-wrap td.active a {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

div.note-design-wrap td.active.event a {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

div.note-design-wrap td.active a:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

div.note-design-wrap td.active.event a:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

ul.terms-alphabet li.selected a {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 96, 131);
}

.woocommerce .row a.button {
  background-color: rgb(0, 96, 131);
}

.woocommerce-page a.button {
  background-color: rgb(0, 96, 131);
}

.hover-colorful:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(0, 96, 131) !important;
}

.quotes-arrows li a:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(0, 96, 131) !important;
}

.light.bluebox-button:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(0, 96, 131) !important;
}

.default.tparrows:hover {
  color: rgb(255, 255, 255) !important;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.06) !important;
  background-color: rgb(0, 96, 131) !important;
}

.bluebox-heading-arrows a.bluebox-shadows:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(0, 96, 131) !important;
}

ul.bluebox-pagination li a:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(0, 96, 131) !important;
}

a.mobile-nav-button span {
  background-color: rgb(0, 96, 131);
}

.image-wrap:hover .image-wrap-mask.image-details-link {
  background-color: rgba(0, 96, 131, 0.8);
}

.bluebox-single-staff-wrap:hover .image-wrap-mask.image-details-link {
  background-color: rgba(0, 96, 131, 0.8);
}

.nav-style-one ul.prime-nav > li.page_item > a:hover {
  box-shadow: inset 0px -4px 0px 0px rgb(0, 96, 131);
}

.nav-style-one ul.prime-nav > li.menu-item > a:hover {
  box-shadow: inset 0px -4px 0px 0px rgb(0, 96, 131);
}

.nav-style-two ul.prime-nav > li.page_item > a:hover {
  box-shadow: inset 0px -4px 0px 0px rgb(0, 96, 131);
}

.nav-style-two ul.prime-nav > li.menu-item > a:hover {
  box-shadow: inset 0px -4px 0px 0px rgb(0, 96, 131);
}

.nav-style-three ul.prime-nav > li.page_item > a:hover {
  box-shadow: inset 0px -4px 0px 0px rgb(0, 96, 131);
}

.nav-style-three ul.prime-nav > li.menu-item > a:hover {
  box-shadow: inset 0px -4px 0px 0px rgb(0, 96, 131);
}

.nav-style-four ul.prime-nav > li.page_item > a:hover {
  box-shadow: inset 0px -4px 0px 0px rgb(0, 96, 131);
}

.nav-style-four ul.prime-nav > li.menu-item > a:hover {
  box-shadow: inset 0px -4px 0px 0px rgb(0, 96, 131);
}

.nova-slider-bottom-nav .bluebox-slider-content ul li a:hover {
  color: rgb(255, 255, 255);
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.06), inset 0px -4px 0px 0px rgb(0, 96, 131);
  background-color: rgba(0, 0, 0, 0.86);
}

ul.prime-nav li > ul.bluebox-dropdown {
  border-top-color: rgb(0, 96, 131);
  border-top-width: 4px;
  border-top-style: solid;
}

.quote-fancy.bluebox-quotes p {
  border-top-color: rgb(0, 96, 131);
  border-top-width: 4px;
  border-top-style: solid;
}

ul.bluebox-pricing-table li > div ul li.price-row div::after {
  border-bottom-color: rgb(0, 96, 131);
  border-bottom-width: 6px;
  border-bottom-style: solid;
}

.bluebox-partners ul.partners-slide li a:hover::after {
  border-bottom-color: rgb(0, 96, 131);
  border-bottom-width: 6px;
  border-bottom-style: solid;
}

.nova-slider-bottom-nav .bluebox-slider-content ul li a:hover::after {
  border-bottom-color: rgb(0, 96, 131);
  border-bottom-width: 6px;
  border-bottom-style: solid;
}

.bluebox-staff .content-details::after {
  border-bottom-color: rgb(0, 96, 131);
  border-bottom-width: 6px;
  border-bottom-style: solid;
}

.quote-fancy.bluebox-quotes p::after {
  border-bottom-color: rgb(0, 96, 131);
  border-bottom-width: 6px;
  border-bottom-style: solid;
}

ul.bluebox-pricing-table li > div ul li.price-row div {
  border-bottom-color: rgb(0, 96, 131);
  border-bottom-width: 4px;
  border-bottom-style: solid;
}

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li a:hover span {
  border-right-color: rgb(0, 96, 131);
  border-right-width: 4px;
  border-right-style: solid;
}

.nova-slider-four .bluebox-slider-content .heading-container a.slide-link:hover::after {
  border-right-color: rgb(0, 96, 131);
  border-right-width: 6px;
  border-right-style: solid;
}

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li a:hover::after {
  border-left-color: rgb(0, 96, 131);
  border-left-width: 6px;
  border-left-style: solid;
}

.nova-slider-four .bluebox-slider-content .extra-info.slide-details .details-wrap::after {
  border-left-color: rgb(0, 96, 131);
  border-left-width: 6px;
  border-left-style: solid;
}

.bluebox-single-staff-wrap .top-staff-wrap .single-staff-left-wrap::after {
  border-left-color: rgb(0, 96, 131);
  border-left-width: 6px;
  border-left-style: solid;
}

.bluebox-events-list:hover .event-date-line::after {
  border-left-color: rgb(0, 96, 131);
  border-left-width: 6px;
  border-left-style: solid;
}

.nova-slider-four .bluebox-slider-content .extra-info.slide-details .details-wrap {
  color: rgba(255, 255, 255, 0.5);
  border-left-color: rgb(0, 96, 131);
  border-left-width: 4px;
  border-left-style: solid;
}

.nova-slider-four .bluebox-slider-content .heading-container a.slide-link:hover {
  color: rgb(255, 255, 255);
  border-left-color: rgb(0, 96, 131);
  border-left-width: 4px;
  border-left-style: solid;
}

.bluebox-new-tabs-element > .nav-left ul li.active::before {
  border-left-color: rgb(0, 96, 131);
  border-left-width: 4px;
  border-left-style: solid;
}

.tp-caption a {
  color: rgb(255, 255, 255) !important;
}

.hover-dark:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgba(0, 0, 0, 0.74) !important;
}

.bluebox-slider-content .slide-details .details-wrap a.bluebox-button:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgba(0, 0, 0, 0.74) !important;
}

.bluebox-fancy-text .bluebox-button:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgba(0, 0, 0, 0.74) !important;
}

.quote-colorful .quotes-arrows li a:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgba(0, 0, 0, 0.74) !important;
}

.text-box-style-three .bluebox-button:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgba(0, 0, 0, 0.74) !important;
}

.bluebox-pricing-table .bluebox-button:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgba(0, 0, 0, 0.74) !important;
}

.hover-dark:hover span {
  color: rgb(255, 255, 255) !important;
}

.bluebox-slider-content .slide-details .details-wrap a.bluebox-button:hover span {
  color: rgb(255, 255, 255) !important;
}

.bluebox-fancy-text .bluebox-button:hover span {
  color: rgb(255, 255, 255) !important;
}

.quote-colorful .quotes-arrows li a:hover span {
  color: rgb(255, 255, 255) !important;
}

.text-box-style-three .bluebox-button:hover span {
  color: rgb(255, 255, 255) !important;
}

.bluebox-pricing-table .bluebox-button:hover span {
  color: rgb(255, 255, 255) !important;
}

.roki-button-link:hover span {
  color: rgb(255, 255, 255) !important;
}

.hover-colorful:hover span {
  color: rgb(255, 255, 255) !important;
}

.quotes-arrows li a:hover span {
  color: rgb(255, 255, 255) !important;
}

.light.bluebox-button:hover span {
  color: rgb(255, 255, 255) !important;
}

.portfolio-loop .project-half > .half-content .bluebox-button:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgba(0, 0, 0, 0.74) !important;
}

.portfolio-loop .project-half > .half-content .bluebox-button:hover span {
  color: rgb(255, 255, 255) !important;
}

.extra.bluebox-button span {
  color: rgb(255, 255, 255);
}

.roki-button-link span {
  color: rgb(255, 255, 255);
}

.image-wrap a.icon-search {
  color: rgb(255, 255, 255);
}

.nova-slider-four .bluebox-slider-content .heading-container a.slide-link {
  color: rgba(255, 255, 255, 0.26);
  border-left-color: currentColor;
  border-left-width: 4px;
  border-left-style: solid;
}

.bluebox-authors-wrap .bluebox-authors-content a.back-to-top {
  color: rgba(255, 255, 255, 0.26);
}

.bluebox-footer-content a.social-link {
  color: rgba(255, 255, 255, 0.26);
}

.bluebox-prime-nav #prime-nav-searchform i {
  color: rgba(255, 255, 255, 0.5);
}

.bluebox-slider-content .slide-details .details-wrap .details-content p {
  color: rgba(255, 255, 255, 0.5);
}

.bluebox-accordion .ui-state-active.accordion-heading a.accordion-button {
  color: rgba(255, 255, 255, 0.5);
}

.fancy-colorful.bluebox-fancy-text .fancy-content p {
  color: rgba(255, 255, 255, 0.5);
}

.quote-colorful.bluebox-quotes p {
  color: rgba(255, 255, 255, 0.5);
}

.bluebox-footer-content {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.74);
}

.bluebox-copyrights-wrap {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.8);
}

.bluebox-footer-content a {
  color: rgba(255, 255, 255, 0.5);
}

.bluebox-copyrights-wrap a {
  color: rgba(255, 255, 255, 0.5);
}

.bluebox-footer-content .bluebox-contact input[type='text'] {
  color: rgba(255, 255, 255, 0.5);
}

.bluebox-footer-content .bluebox-contact textarea {
  color: rgba(255, 255, 255, 0.5);
}

.bluebox-footer-content .widget-wrap form.search-form input.text-input {
  color: rgba(255, 255, 255, 0.5);
}

#mobile-menu .prime-nav-mobile-list li > i {
  color: rgba(255, 255, 255, 0.3);
}

#mobile-menu .prime-nav-mobile-list li a {
  color: rgba(255, 255, 255, 0.9);
}

#mobile-menu .prime-nav-mobile-list li a.dropdown-link {
  color: rgba(255, 255, 255, 0.6);
}

#mobile-menu .prime-nav-mobile-list li a.dropdown-link:hover {
  color: rgba(255, 255, 255, 0.6);
}

.bluebox-prime-nav #prime-nav-searchform form input[type='text'].bluebox-search-input {
  color: rgb(255, 255, 255);
}

.nav-style-two ul.prime-nav > li > a {
  color: rgb(255, 255, 255);
}

.dark-dropdown.bluebox-header ul.prime-nav li > ul.bluebox-dropdown li a {
  color: rgb(255, 255, 255);
}

.bluebox-slider-content .slide-details .details-wrap .details-content {
  color: rgb(255, 255, 255);
}

.nova-slider-bottom-nav .bluebox-slider-content ul li a {
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.74);
}

.nova-slider-bottom-nav .bluebox-slider-content ul li a span {
  color: rgb(255, 255, 255);
}

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li a {
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.74);
}

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li a span {
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.16);
}

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li a:hover {
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.86);
}

.nova-slider-four .bluebox-slider-content .heading-container {
  color: rgb(255, 255, 255);
}

.roki-rev-slide-vl-opt4-opt2 {
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.74);
}

.roki-rev-slide-vl-no-opt2 {
  color: rgb(255, 255, 255);
}

.roki-rev-slide-l-opt4-opt2 {
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.74);
}

.roki-rev-slide-l-no-opt2 {
  color: rgb(255, 255, 255);
}

.roki-rev-slide-n-opt4-opt2 {
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.74);
}

.roki-rev-slide-n-no-opt2 {
  color: rgb(255, 255, 255);
}

.bluebox-accordion .ui-state-active.accordion-heading a.accordion-button:hover {
  color: rgb(255, 255, 255);
}

.progress .bar > p {
  color: rgb(255, 255, 255);
}

.prev-next-links a {
  color: rgb(255, 255, 255);
}

.prev-next-links a:hover {
  color: rgb(255, 255, 255);
}

.bluebox-contact input[type='submit']:hover {
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.74);
}

.bluebox-icon-container {
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.74);
}

.bluebox-icon-container span {
  color: rgb(255, 255, 255);
}

.fancy-colorful.bluebox-fancy-text h4 {
  color: rgb(255, 255, 255);
}

.quote-colorful.bluebox-quotes em {
  color: rgb(255, 255, 255);
}

.quote-colorful.bluebox-quotes .quotes-arrows li a {
  color: rgb(255, 255, 255);
}

.quote-colorful.quote-fancy.bluebox-quotes p strong {
  color: rgb(255, 255, 255);
}

.bluebox-twitter-element {
  color: rgb(255, 255, 255);
}

.blog-loop-standard .line-details div {
  color: rgb(255, 255, 255);
}

.portfolio-loop .line-details div {
  color: rgb(255, 255, 255);
}

.bluebox-footer-content .widget-wrap ul.bluebox-widget-menu a span {
  color: rgba(255, 255, 255, 0.16);
}

.bluebox-footer-content h3.bluebox-heading {
  color: rgb(255, 255, 255);
}

.bluebox-footer-content .widget-wrap .heading-wrap h3 {
  color: rgb(255, 255, 255);
}

.bluebox-footer-content .tagcloud a:hover {
  color: rgb(255, 255, 255);
}

.new-builder-element.bluebox-single-staff-wrap:hover .single-staff-right-wrap .page-heading-wrap .staff-more-link {
  color: rgb(255, 255, 255);
}

.bluebox-events-list:hover .event-date-line .date-details-wrap strong {
  color: rgb(255, 255, 255);
}

.top-staff-wrap .image-wrap ul.social-links li a {
  color: rgb(255, 255, 255);
}

.bluebox-events-list:hover .event-details .event-inner-content-wrap .event-more-link {
  color: rgb(255, 255, 255);
}

.bluebox-new-blog-element .blog-new-heading-wrap a.head-link {
  color: rgb(255, 255, 255);
}

.bluebox-new-blog-element .blog-new-heading-wrap > .inner-wrap .new-blog-date {
  color: rgb(255, 255, 255);
}

.bluebox-new-blog-element .blog-new-post-format-icon {
  color: rgb(255, 255, 255);
}

.bluebox-new-blog-element .image-wrap a > i {
  color: rgb(255, 255, 255);
}

.large.bluebox-new-tabs-element > .nav-left ul li.active > a > span {
  color: rgb(255, 255, 255);
}

.bluebox-new-tabs-element > .nav-left ul li.active > a:hover > span {
  color: rgb(255, 255, 255);
}

.roki-rev-slide-vl-opt2-opt4 {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
}

.roki-rev-slide-l-opt2-opt4 {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
}

.roki-rev-slide-n-opt2-opt4 {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
}

.quote-colorful.quote-fancy.bluebox-quotes p {
  border-top-color: rgb(255, 255, 255);
  border-top-width: 4px;
  border-top-style: solid;
}

.bluebox-slider-wrap::before {
  border-bottom-color: rgba(255, 255, 255, 0.06);
  border-bottom-width: 8px;
  border-bottom-style: solid;
}

.quote-colorful.quote-fancy.bluebox-quotes p::after {
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-width: 6px;
  border-bottom-style: solid;
}

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li.current a span {
  border-right-color: rgb(255, 255, 255);
  border-right-width: 4px;
  border-right-style: solid;
}

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li.current a:hover span {
  border-right-color: rgb(255, 255, 255);
  border-right-width: 4px;
  border-right-style: solid;
}

.nova-slider-four .bluebox-slider-content .heading-container a.slide-link::after {
  border-right-color: rgba(255, 255, 255, 0.2);
  border-right-width: 6px;
  border-right-style: solid;
}

.blog-3-cols .image-wrap::before {
  border-right-color: rgba(255, 255, 255, 0.06);
  border-right-width: 8px;
  border-right-style: solid;
}

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li.current a::after {
  border-left-color: rgb(255, 255, 255);
  border-left-width: 6px;
  border-left-style: solid;
}

.nova-right-tabs-slider .bluebox-slider-content .slide-details ul li.current a:hover::after {
  border-left-color: rgb(255, 255, 255);
  border-left-width: 6px;
  border-left-style: solid;
}

body a {
  color: rgb(0, 0, 0);
}

.light.bluebox-button {
  color: rgb(0, 0, 0) !important;
}

.nav-style-three ul.prime-nav > li::after {
  color: rgba(0, 0, 0, 0.16);
}

.a-to-z-terms.table-striped tbody tr td.first-letter {
  color: rgba(0, 0, 0, 0.16);
}

.widget-wrap ul.bluebox-widget-menu a span {
  color: rgba(0, 0, 0, 0.16);
}

.bluebox-heading-arrows a.bluebox-shadows {
  color: rgba(0, 0, 0, 0.26);
}

.bluebox-heading-wrap .prev-next-heading-links a {
  color: rgba(0, 0, 0, 0.26);
}

.bluebox-accordion .accordion-heading a.accordion-button {
  color: rgba(0, 0, 0, 0.26);
}

.nav-tabs > li > a > span {
  color: rgba(0, 0, 0, 0.26);
}

.text-box-style-two .head-link span {
  color: rgba(0, 0, 0, 0.26);
}

.quotes-arrows li a {
  color: rgba(0, 0, 0, 0.26);
}

.extra.light.bluebox-button span {
  color: rgba(0, 0, 0, 0.26);
}

.bluebox-single-staff-wrap .bottom-staff-wrap .single-staff-left-wrap .single-social-link span {
  color: rgba(0, 0, 0, 0.26);
}

.cal-page-month-wrap a.nav-arrow {
  color: rgba(0, 0, 0, 0.26);
}

.bluebox-events-list .event-details .page-heading-wrap ul.event-rate-list li {
  color: rgba(0, 0, 0, 0.26);
}

.bluebox-new-tabs-element > .nav-left ul li > a > span {
  color: rgba(0, 0, 0, 0.26);
}

.light.bluebox-info-bar {
  color: rgba(0, 0, 0, 0.5);
}

.page-heading-wrap h2 span {
  color: rgba(0, 0, 0, 0.5);
}

.page-heading-wrap .bluebox-heading-details .bluebox-heading-search > i {
  color: rgba(0, 0, 0, 0.5);
}

ul.bluebox-pagination li a {
  color: rgba(0, 0, 0, 0.5);
}

.portfolio-cols .project-post-details p {
  color: rgba(0, 0, 0, 0.5);
}

.tagline a {
  color: rgba(0, 0, 0, 0.5);
}

.load-more-link {
  color: rgba(0, 0, 0, 0.5);
}

.bluebox-accordion .accordion-content {
  color: rgba(0, 0, 0, 0.5);
}

ul.bluebox-pricing-table {
  color: rgba(0, 0, 0, 0.5);
}

.bluebox-staff .content-details span.position {
  color: rgba(0, 0, 0, 0.5);
}

.bluebox-textboxes p {
  color: rgba(0, 0, 0, 0.5);
}

.bluebox-fancy-text .fancy-content p {
  color: rgba(0, 0, 0, 0.5);
}

.bluebox-quotes p {
  color: rgba(0, 0, 0, 0.5);
}

.line-meta-details {
  color: rgba(0, 0, 0, 0.5);
}

.blog-loop-standard p {
  color: rgba(0, 0, 0, 0.5);
}

.portfolio-loop em.tags a {
  color: rgba(0, 0, 0, 0.5);
}

.portfolio-loop p {
  color: rgba(0, 0, 0, 0.5);
}

ol.comment-list li.comment div.comment-meta a {
  color: rgba(0, 0, 0, 0.5);
}

.tagcloud a {
  color: rgba(0, 0, 0, 0.5);
}

.bluebox-events-list .event-date-line {
  color: rgba(0, 0, 0, 0.5);
}

.single-post.bluebox-events-list .event-date-line {
  color: rgba(0, 0, 0, 0.5);
}

.single-post.bluebox-events-list:hover .event-date-line {
  color: rgba(0, 0, 0, 0.5);
}

.bluebox-new-tabs-element > .nav-left ul .sub-menu li a {
  color: rgba(0, 0, 0, 0.5);
}

div.note-design-wrap td a {
  color: rgba(0, 0, 0, 0.5);
}

div.note-design-wrap td.off a {
  color: rgba(0, 0, 0, 0.5);
}

ul.terms-alphabet li.no-terms a {
  color: rgba(0, 0, 0, 0.5);
}

.a-to-z-terms.table-striped tbody tr td.term-meaning p {
  color: rgba(0, 0, 0, 0.5);
}

.default.tparrows {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.06) !important;
  background-color: rgba(0, 0, 0, 0.06) !important;
}

.roki-rev-slide-vl-no-opt4 {
  color: rgb(0, 0, 0);
}

.roki-rev-slide-l-no-opt4 {
  color: rgb(0, 0, 0);
}

.roki-rev-slide-n-no-opt4 {
  color: rgb(0, 0, 0);
}

h3.bluebox-heading {
  color: rgb(0, 0, 0);
}

.bluebox-contact label {
  color: rgb(0, 0, 0);
}

ul.bluebox-pricing-table strong {
  color: rgb(0, 0, 0);
}

.quote-fancy.bluebox-quotes p strong {
  color: rgb(0, 0, 0);
}

ol.comment-list li.comment div.comment-author cite a {
  color: rgb(0, 0, 0);
}

.bluebox-events-list .event-date-line::before {
  color: rgb(0, 0, 0);
}

.bluebox-events-list .event-date-line .date-details-wrap strong {
  color: rgb(0, 0, 0);
}

.top-staff-wrap .image-wrap ul.social-links li a:hover {
  color: rgb(0, 0, 0);
}

.single-post.bluebox-events-list:hover .event-date-line .date-details-wrap strong {
  color: rgb(0, 0, 0);
}

.bluebox-new-tabs-element > .nav-left ul li > a:hover > span {
  color: rgb(0, 0, 0);
}

.nav-style-two .bluebox-head-bottom {
  background-color: rgba(0, 0, 0, 0.74);
}

.nav-style-two.type-two.bluebox-header ul.prime-nav {
  background-color: rgba(0, 0, 0, 0.74);
}

.dark-dropdown.bluebox-header ul.prime-nav li > ul.bluebox-dropdown {
  background-color: rgba(0, 0, 0, 0.74);
}

.bluebox-slider-content .slide-details {
  background-color: rgba(0, 0, 0, 0.74);
}

.blog-loop-standard .line-details .line-post-format {
  background-color: rgba(0, 0, 0, 0.74);
}

.simplebullets.tp-bullets .bullet:hover {
  background-color: rgba(0, 0, 0, 0.74) !important;
}

.simplebullets.tp-bullets .selected.bullet {
  background-color: rgba(0, 0, 0, 0.74) !important;
}

.nova-slider-bottom-nav .bluebox-slider-content ul li a span {
  background-color: rgba(0, 0, 0, 0.16);
}

.simplebullets.tp-bullets .bullet {
  background-color: rgba(0, 0, 0, 0.26) !important;
}

.bluebox-twitter-element::after {
  border-bottom-color: rgb(0, 0, 0);
  border-bottom-width: 6px;
  border-bottom-style: solid;
}

.bluebox-footer-content:after {
  border-bottom-color: rgba(0, 0, 0, 0.74);
  border-bottom-width: 6px;
  border-bottom-style: solid;
}

.bluebox-events-list .event-date-line::after {
  border-left-color: rgb(0, 0, 0);
  border-left-width: 6px;
  border-left-style: solid;
}

body {
  font-family: Open Sans;
}

h1 {
  font-family: Open Sans;
}

h2 {
  font-family: Open Sans;
}

h3 {
  font-family: Open Sans;
}

h4 {
  font-family: Open Sans;
}

h5 {
  font-family: Open Sans;
}

.bluebox-wrap {
  max-width: 100%;
}

.bluebox-container {
  max-width: 940px;
}

.bluebox-snap-content-left {
  left: 17.36%;
}

.bluebox-snap-content-right {
  right: 17.36%;
}

.nav-tabs > li.active:before {
  border-left: 4px solid rgb(0, 96, 131);
}

.nav-tabs > li > a:hover {
  color: rgb(0, 96, 131);
}

.nav-tabs > li.active > a {
  color: rgb(0, 96, 131) !important;
}

.nav-tabs > li.active > a:before {
  background-color: rgb(0, 96, 131);
}